import useHologramPageView from "hooks/useHologramPageView"
import { AppState, useStore } from "hooks/useStore"
import { classNames } from "lib/classNames"
import { MutableRefObject, useCallback, useEffect } from "react"
import { useResizeDetector } from "react-resize-detector"
import { EmbedHologramProps } from "../../lib/hologramProps"
import HTMLHologramRenderer from "./renderers/HTMLHologramRenderer"
import RGBDHologramRenderer from "./renderers/RGBDHologramRenderer"
import THREEHologramRenderer from "./renderers/THREEHologramRenderer"

export interface RendererProps extends EmbedHologramProps {
	viewcone: number
	/** The container's width */
	width: number
	/** The container's height */
	height: number
	/** Called when the hologram is fully loaded (after the quilt is loaded) */
	onLoad?: () => void
	/** Triggered when the viewer angle is moved */
	onViewAngleChange: (xRot: number, xRotContainer?: number, yRot?: number, yRotContainer?: number) => void
	/** Set the view angle manually */
	setViewAngle?: (angle: number) => void
	/** DPR */
	dpr: number
	/** A reference to the hologram HTML element */
	containerRef?: MutableRefObject<HTMLElement | null>
	isEditMode?: boolean
	setFocus?: (focus: number) => void
	depthOverrideUrl?: string
	overrideAspect?: number
}

// Defaults
const propDefaults: Omit<EmbedHologramProps, "hologram"> = {
	enableOnLookingGlass: false,
	enableVR: false,
	enableAngleStore: false,
	dropshadow: true,
	viewcone: 45,
	enableIntersectionObserver: true,
	wiggle: true,
	webgl: false,
	className: "",
	fullscreen: false,
	wiggleContainerRef: null,
	animate: false,
	showCreator: false,
	showTitle: false,
	viewBlending: true,
	analytics: true,
	frameTilt: true,
	setFocus: () => {},
	overrideAspect: undefined,
}

export default function HologramEmbed(props: EmbedHologramProps) {
	// Merge parameters with defaults
	props = { ...propDefaults, ...props }

	let {
		enableAngleStore,
		viewcone,
		hologram,
		analytics,
		webgl,
		overrideAspect,
		depthOverrideURL,
		className,
	} = props

	const aspectRatio = hologram?.aspectRatio ?? 1

	const setHologramAngle = useStore((state: AppState) => state.setHologramAngle)
	const {
		width,
		height,
		ref: containerRef,
	} = useResizeDetector<HTMLDivElement>({}) as {
		width: number
		height: number
		ref: MutableRefObject<HTMLDivElement | null>
	}

	const dpr = typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1

	// Update the global angle store when the user wiggles the hologram
	// rotY is a normalized view angle between -1 and 1
	const onViewAngleChange = useCallback(
		(rotY: number) => {
			if (enableAngleStore) {
				setHologramAngle(rotY)
			}
		},
		[enableAngleStore],
	)

	const rendererProps: RendererProps = {
		...props,
		viewcone: props.viewcone ?? propDefaults.viewcone ?? 45,
		setFocus: props.setFocus,
		/** Sometimes the width is not set and need to use offsetWidth instead */
		width: (width == 0 ? containerRef.current?.offsetWidth : width) || 720,
		height: (height == 0 ? containerRef.current?.offsetHeight : height) || 1080,
		onViewAngleChange,
		onLoad: () => {},
		dpr,
		containerRef,
		overrideAspect,
		depthOverrideURL,
	}

	useHologramPageView({ hologram: props?.hologram, skip: !analytics })
	useTrackLoadedHolograms()

	if (!hologram) {
		return <></>
	}

	return (
		<div
			key={hologram.id}
			ref={containerRef}
			className={className ?? "h-full w-full"}
			style={{ aspectRatio: overrideAspect ?? aspectRatio }}>
			<>
				{/* No longer used */}
				{webgl && hologram.type == "QUILT" && <THREEHologramRenderer {...rendererProps} />}

				{!webgl && hologram.type == "QUILT" && <HTMLHologramRenderer {...rendererProps} />}

				{hologram.type == "RGBD" && <RGBDHologramRenderer {...rendererProps} />}
			</>
		</div>
	)
}

/** Track how many holograms are appearing on the page (used by SmallGyroUI) */
function useTrackLoadedHolograms() {
	const updateNumHolograms = useStore((state) => state.updateNumHolograms)
	useEffect(() => {
		updateNumHolograms(1)
		return () => updateNumHolograms(-1)
	}, [updateNumHolograms])
}
