//@ts-nocheck
import { MeshProps, ShaderMaterialProps, useLoader, useThree } from "@react-three/fiber"
import useGyro from "hooks/useGyro"
import QuiltTextureLoader from "lib/QuiltTextureLoader"
import { MutableRefObject, Suspense, useEffect, useMemo, useRef, useState } from "react"
import { FrontSide, MathUtils, Mesh, ShaderMaterial } from "three"

// @refresh reset

// @ts-ignore
import fragShader from "components/embed/shaders/quilt.frag"

// @ts-ignore
import vertShader from "components/embed/shaders/quilt.vert"
import { getBlurredImagePreview, getQuiltByPreferredLenticularizedWidth } from "lib/utils.hologram"
import { generateUUID } from "three/src/math/MathUtils"
import {
	HOLOGRAM_DEFAULT_ASPECT_RATIO,
	HOLOGRAM_DEFAULT_QUILT_COLS,
	HOLOGRAM_DEFAULT_QUILT_ROWS,
	HOLOGRAM_DEFAULT_TILE_COUNT,
} from "prisma/models/Holograms.model"
import { ImageAsset } from "@prisma/client"
import { EmbedHologram } from "lib/hologramProps"

// TODO ideally ViewLayout used a better typed interface?
export interface QuiltViewLayout {
	rows: number
	columns: number
}

export interface QuiltProps {
	hologram: EmbedHologram
	resolution?: number
	radius?: number
	viewcone: number
	onLoadComplete?: () => void
}

/**
 * @deprecated
 */
export default function Quilt({
	hologram,
	resolution = 500,
	radius = 0.03,
	viewcone,
	onLoadComplete,
}: QuiltProps) {
	const { gl } = useThree()

	// three js objects
	const mesh = useRef<Mesh>()
	const matRef = useRef<ShaderMaterial>()
	const viewCount = hologram.quiltTileCount ?? HOLOGRAM_DEFAULT_TILE_COUNT
	const quiltCols = hologram.quiltCols ?? HOLOGRAM_DEFAULT_QUILT_COLS
	const quiltRows = hologram.quiltRows ?? HOLOGRAM_DEFAULT_QUILT_ROWS
	const aspect = hologram.aspectRatio ?? HOLOGRAM_DEFAULT_ASPECT_RATIO
	const bleed = 0.005

	const meshProps = useMemo((): MeshProps => {
		return {
			scale: aspect > 1 ? [1, 1 / aspect, 1] : [aspect, 1, 1],
			position: [0, 0, 0],
		}
	}, [aspect])

	const [shaderMaterialKey, setShaderMaterialKey] = useState<string>(generateUUID())

	// Update uniforms when state changes
	// Send to looking glass if necessary
	useEffect(() => {
		const scale = aspect > 1 ? [1, 1 / aspect, 1] : [aspect, 1, 1]
		mesh.current?.scale?.set(scale[0], scale[1], scale[2])

		if (matRef.current) {
			const { u_view_layout, u_view_count, u_aspect } = matRef.current.uniforms
			u_aspect.value = aspect
			u_view_layout.value = [quiltCols, quiltRows]
			u_view_count.value = viewCount
			matRef.current.uniformsNeedUpdate = true
			setShaderMaterialKey(generateUUID())
		}
	}, [hologram.id, quiltCols, quiltRows, viewCount, aspect])

	const quiltImageUrl: string | null = useMemo(() => {
		return getQuiltByPreferredLenticularizedWidth(hologram, resolution, gl.capabilities.maxTextureSize)
	}, [hologram.id, resolution, gl.capabilities.maxTextureSize])

	let materialProps: QuiltShaderMaterialProps = {
		quiltTextureUrl: quiltImageUrl,
		quiltCols,
		quiltRows,
		viewCount,
		isFullQuilt: true,
		matRef,
		radius,
		bleed,
		resolution,
		aspect,
		viewcone,
		shaderMaterialKey,
		onLoadComplete,
	}

	// callback for orientation events from a mobile device's accelerometer
	{
		const { invalidate } = useThree()
		useGyro(
			(viewDelta) => {
				if (!matRef.current) return
				matRef.current.uniforms.u_view_delta.value = viewDelta
				matRef.current.uniformsNeedUpdate = true
				invalidate()
			},
			[matRef, invalidate],
		)
	}

	const blurredPreview = getBlurredImagePreview(hologram.thumbnail as ImageAsset)

	return (
		<mesh ref={mesh} {...meshProps}>
			<planeGeometry />
			<Suspense
				fallback={
					<QuiltShaderMaterial
						{...materialProps}
						quiltTextureUrl={blurredPreview?.url || quiltImageUrl}
						quiltCols={1}
						quiltRows={1}
						viewCount={1}
						isFullQuilt={false}
						matRef={null}
					/>
				}>
				<QuiltShaderMaterial {...materialProps} />
			</Suspense>
		</mesh>
	)
}

interface QuiltShaderMaterialProps {
	quiltTextureUrl: string
	quiltCols: number
	quiltRows: number
	viewCount: number
	isFullQuilt: boolean
	matRef: MutableRefObject<ShaderMaterial>
	radius: number
	bleed: number
	resolution: number
	aspect: number
	viewcone: number
	shaderMaterialKey: string
	onLoadComplete?: () => void
}

function QuiltShaderMaterial(props: QuiltShaderMaterialProps) {
	const quiltTexture = useLoader(QuiltTextureLoader as any, props.quiltTextureUrl) as any // TODO: let's not use `any` here

	useEffect(() => {
		if (props.isFullQuilt) {
			props?.onLoadComplete?.()
		}

		return () => {
			if (quiltTexture) {
				// console.log("Unloading quilt", props.quiltTextureUrl)
				quiltTexture.dispose()
			}
		}
	}, [quiltTexture, props.isFullQuilt, props.matRef])

	const matProps = useMemo((): ShaderMaterialProps => {
		return {
			uniforms: {
				u_image: { value: quiltTexture },
				u_view_layout: { value: [props.quiltCols, props.quiltRows] },
				u_view_count: { value: props.viewCount },
				u_viewcone: { value: props.viewcone * MathUtils.DEG2RAD },
				u_aspect: { value: props.aspect },
				u_resolution: { value: props.resolution },
				u_radius: { value: props.radius },
				u_bleed: { value: props.bleed },
				u_view_delta: { value: 0 },
			},
			side: FrontSide,
			fragmentShader: fragShader,
			vertexShader: vertShader,
		}
	}, [props.quiltCols, props.quiltRows, props.viewCount, quiltTexture, props.radius])

	return <shaderMaterial key={props.shaderMaterialKey} ref={props.matRef} {...matProps} />
}
