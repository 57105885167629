import React, { useRef, useEffect, useState, useCallback } from "react"
import * as THREE from "three"
import { useTexture } from "@react-three/drei"
import { HOLOGRAM_MAX_RGBD_DEPTHINESS, HOLOGRAM_MIN_RGBD_DEPTHINESS } from "prisma/models"
import { useThree } from "@react-three/fiber"

const normalizeValue = (value: number, min: number, max: number): number => {
	const normalizedValue = (value - min) / (max - min)
	const clamp = Math.min(Math.max(normalizedValue, 0.01), 1)
	return clamp
}

function DisplacedGeometry(args: {
	depthUrl: string
	colorUrl: string
	aspect: number
	depthiness: number
	onPointerDown: any
	onPointerMove: any
	onPointerUp: any
	meshRef: any
	children: any
}) {
	const {
		depthUrl,
		colorUrl,
		aspect,
		depthiness,
		onPointerDown,
		onPointerUp,
		onPointerMove,
		meshRef,
		children,
	} = args
	const [localDepth, setLocalDepth] = useState(normalizeValue(depthiness, 0.1, 0.3))
	const [geometry] = useState(() => new THREE.PlaneGeometry(1, 1, 320, 320))
	const displacementMap = useTexture(depthUrl)
	const canvasRef = useRef<HTMLCanvasElement | null>(null)

	const createCanvas = useCallback(() => {
		const canvas = document.createElement("canvas")
		canvas.id = "DisplacementMeshReadCanvas"
		canvasRef.current = canvas
		return canvas
	}, [])

	const { viewport } = useThree()

	useEffect(() => {
		if (!displacementMap) {
			return
		}
		if (!geometry) {
			return
		}

		const canvas = canvasRef.current || createCanvas()
		const ctx = canvas.getContext("2d", { willReadFrequently: true })
		if (!ctx) {
			return
		}
		const image = displacementMap.image as HTMLImageElement
		canvas.width = image.width
		canvas.height = image.height

		ctx.drawImage(image, 0, 0)

		const widthSegments = geometry.parameters.widthSegments + 1
		const heightSegments = geometry.parameters.heightSegments + 1
		const widthStep = image.width / widthSegments
		const heightStep = image.height / heightSegments

		for (let h = 0; h < heightSegments; h++) {
			for (let w = 0; w < widthSegments; w++) {
				const imgData = ctx.getImageData(Math.round(w * widthStep), Math.round(h * heightStep), 1, 1).data
				const displacementVal = (imgData[0] / 255.0) * HOLOGRAM_MAX_RGBD_DEPTHINESS
				const idx = h * widthSegments + w
				geometry.attributes.position.setZ(idx, displacementVal)
			}
		}
		geometry.attributes.position.needsUpdate = true
		geometry.computeVertexNormals()

		// Ensure UVs are correct (they should be by default for PlaneGeometry)
		if (!geometry.attributes.uv) {
			console.error("UV attributes missing")
		}
	}, [displacementMap, geometry, createCanvas])

	useEffect(() => {
		return () => {
			if (canvasRef.current) {
				canvasRef.current.remove()
				canvasRef.current = null
			}
		}
	}, [])

	useEffect(() => {
		setLocalDepth(normalizeValue(depthiness, HOLOGRAM_MIN_RGBD_DEPTHINESS, HOLOGRAM_MAX_RGBD_DEPTHINESS))
	}, [depthiness])

	return (
		<mesh
			ref={meshRef}
			geometry={geometry}
			position={[0, 0, 0]}
			// we construct the geometry itself with the aspect ratio in mind, so only need the scale Z here.
			// scaling the Z axis is more efficient than regenerating the mesh every time depthiness changes.
			scale={[viewport.width, viewport.height, localDepth * 3]}
			onPointerDown={onPointerDown}
			onPointerUp={onPointerUp}
			onPointerMove={onPointerMove}>
			{children}
		</mesh>
	)
}

export default React.memo(DisplacedGeometry)
