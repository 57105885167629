import { spring } from "@leva-ui/plugin-spring"
import { easings, useSpring, useSpringRef } from "@react-spring/web"
import { useGesture } from "@use-gesture/react"
import { useControls } from "leva"
import { useCallback, useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import useGyro from "hooks/useGyro"
import { RendererProps } from "components/embed/HologramEmbed"
import { HOLOGRAM_DEFAULT_TILE_COUNT } from "prisma/models/Holograms.model"

export interface HologramControlsProps {
	onViewAngleChange: (xRot: number, xRotContainer: number, yRot: number, yRotContainer: number) => void
	isQuiltLoaded: boolean
	rendererProps: RendererProps
	refresh?: boolean
	circularRotation?: boolean
}
// define a type interface for the rotation struct
interface Rotation {
	x: number // horizontal rotation
	y: number // vertical rotation
	z: number // this should always be 0
}

export default function useHologramControls({
	onViewAngleChange, // on view angle change is defined where the renderer is defined
	isQuiltLoaded,
	rendererProps,
	refresh,
	circularRotation,
}: HologramControlsProps) {
	const { viewcone, wiggle: bootupWiggle, hologram, containerRef, wiggleContainerRef } = rendererProps
	const viewCount = hologram?.quiltTileCount ?? HOLOGRAM_DEFAULT_TILE_COUNT

	// leva gui for tweaking the spring config
	const { speed, config } = useControls({
		speed: isMobile ? 1.7 : 0.6,
		config: spring(
			isMobile
				? {
						mass: 0.2,
						tension: 130,
						friction: 10,
					}
				: {
						mass: 1.0,
						tension: 170,
						friction: 30,
					},
		),
	})

	const azimuth = [-viewcone * 0.01745 * 0.5, viewcone * 0.01745 * 0.5]
	const polar = [-Math.PI / 4, Math.PI / 4]
	const [startRotX, setStartRotX] = useState(
		bootupWiggle
			? (Math.random() * 0.2 + 0.3) * (Math.random() < 0.5 ? -1.0 : 1.0)
			: getNearestViewRotation(0),
	)
	// we don't intend to use vertical parallax for animations, so we'll just set it to 0
	const [startRotY, setStartRotY] = useState(0)

	const [isAnimating, setIsAnimating] = useState(rendererProps.animate)

	// necessary for gyro
	// curious about the strategy of using let variables instead of state here
	const lastRotation = useRef<Rotation>({ x: 0, y: 0, z: 0 })
	const lastOrientation = useRef<Rotation>({ x: 0, y: 0, z: 0 })
	const interval = useRef<any>(null)

	// when the rotation changes, update the callback function in the renderer
	function updateRotChange() {
		onViewAngleChange(
			Math.max(-1, Math.min(1, lastOrientation.current.x + lastRotation.current.x)), // general rotation X axis | Horizontal Rotation
			Math.max(-1, Math.min(1, lastRotation.current.x)), // container value for when accelerometer is being used
			Math.max(-1, Math.min(1, lastOrientation.current.y + lastRotation.current.y)), // general rotation Y axis | Vertical Rotation
			Math.max(-1, Math.min(1, lastRotation.current.y)), // container value for when accelerometer is being used
		)
	}

	if (refresh) {
		updateRotChange()
	}
	// based on gyroscope data, update the rotation
	useGyro((viewDelta) => {
		lastOrientation.current.x = viewDelta.x
		lastOrientation.current.y = viewDelta.y
		updateRotChange()
	}, [])

	// handle updating the starting rotation
	// note that this is only used for the horizontal rotation, vertical rotation does not matter here.
	useEffect(() => {
		let rotationX = (startRotX / (azimuth[1] * 2)) * 2
		lastRotation.current.x = rotationX
		updateRotChange()
	}, [startRotX])

	// use a spring to animate the rotation
	const [hologramSpring, springApi] = useSpring(
		() => ({
			scale: 1,
			rotation: [startRotY, startRotX, 0], // X, Y, Z || width, height, depth
			config,
			onChange: (props) => {
				// computes a value between -1 and 1
				let rotationX = (props.value.rotation[1] / (azimuth[1] * 2)) * 2 // horizontal rotation
				let rotationY = (props.value.rotation[0] / (polar[1] * 2)) * 2 // vertical rotation
				lastRotation.current.x = rotationX
				lastRotation.current.y = rotationY
				updateRotChange()
			},
			onRest: (obj) => {
				if (hologram?.type === "QUILT") {
					snapToNearestView(obj.value.rotation[1])
				}
			},
		}),
		[config, bootupWiggle, startRotX, viewCount],
	)

	// specific to HTML/Quilt Rendering, get the closest view from the quilt.
	function getNearestViewRotation(rotationY: number) {
		let snappedRot = rotationY / (azimuth[1] * 2) + 0.5
		snappedRot = snappedRot * (viewCount - 1)
		// snappedRot = (dir > 0 ? Math.ceil(snappedRot) : Math.floor(snappedRot)) / (viewCount - 1)
		snappedRot = Math.round(snappedRot) / (viewCount - 1)
		snappedRot = (snappedRot - 0.5) * azimuth[1] * 2
		return snappedRot
	}

	// using the function above, snap to the nearest view.
	function snapToNearestView(rotationY: number) {
		if (!springApi) return
		let snappedRot = getNearestViewRotation(rotationY)
		springApi.start({
			rotation: [0, snappedRot, 0],
			config: {
				mass: 3,
				tension: 200,
				friction: 100,
			},
		})
	}

	// handle user input from mouse or touch interface
	const onTouchOrMouseMove = useCallback(
		({ delta: [x, y], memo: [oldY, oldX] = hologramSpring.rotation.animation.to as number[] }) => {
			// if we're animating, stop it and restart it after 3 seconds
			if (rendererProps.animate) {
				clearTimeout(interval.current)
				// console.log("stopping animation")
				setIsAnimating(false)
				interval.current = setTimeout(() => {
					// console.log("restarting animation")
					setIsAnimating(true)
				}, 3000)
			}

			if (containerRef !== undefined) {
				if (containerRef.current !== null) {
					x = oldX + (x / containerRef.current?.getBoundingClientRect().width) * Math.PI * speed
					x = Math.min(Math.max(x, azimuth[0]), azimuth[1])
					y = oldY + (y / containerRef.current?.getBoundingClientRect().height) * Math.PI * speed
					y = Math.min(Math.max(y, polar[0]), polar[1])
				}
			}

			springApi.start({
				rotation: [y, x, 0],
				config,
			})

			return [y, x]
		},
		[speed, config, containerRef, isAnimating],
	)

	// handle user input from mouse or touch interface
	useGesture(
		{
			onDrag: isMobile ? onTouchOrMouseMove : () => {},
			onMove: !isMobile ? onTouchOrMouseMove : () => {},
		},
		{
			target: wiggleContainerRef?.current || containerRef?.current,
			move: {
				mouseOnly: false, // for pointer events from devices like Oculus controllers
			},
			drag: {
				pointer: {
					touch: true,
				},
				preventScroll: true,
			},
		},
	)

	// Commence the wiggling
	useEffect(() => {
		if (bootupWiggle && isQuiltLoaded) {
			randomBounceyStart()
		}
	}, [isQuiltLoaded, bootupWiggle])

	useAnimationLoop({
		animate: isAnimating,
		startAngle: lastRotation.current.x ?? 0,
		onChange: (angle) => {
			let newAngle = angle * Math.PI
			lastRotation.current.x = Math.cos(newAngle)
			lastRotation.current.y = Math.sin(newAngle)
			updateRotChange()
		},
		circular: circularRotation,
	})

	/**Animation to give the Hologram with a cute bounce */
	const randomBounceyStart = useCallback(async () => {
		// wait for fade in
		if (springApi) {
			springApi.start({
				delay: Math.random() * 150 + 50,
				rotation: [0, getNearestViewRotation(0), 0],
				config: {
					mass: Math.random() * 5.0 + 5.0,
					tension: Math.random() * 200 + 400,
					friction: 30,
				},
			})
		}
	}, [springApi])
}

interface LoopProps {
	animate?: boolean
	startAngle?: number
	/** Passes a value between -1 and 1 */
	onChange: (angle: number) => void
	// Determines whether the rotation is circular (X & Y axis) or linear (Y axis)
	circular?: boolean
}

/** Hook for managing the back and forth animation. e.g. https://blocks.glass/embed/208?animate=1 */
function useAnimationLoop(args: LoopProps) {
	const api = useSpringRef()

	const angle = args.startAngle ?? 0
	const circular = args.circular

	const onChange = (props) => {
		// console.log(props.value)
		args.onChange(props.value.angle)
	}

	// Set up a looping animation
	useSpring({
		ref: api,
		from: { angle },
	})

	useEffect(() => {
		if (args.animate) {
			if (circular) {
				api.start({
					from: { angle },
					to: { angle: angle + 2 },
					loop: true,
					config: {
						duration: 8000,
						easing: easings.linear,
					},
					onChange,
				})
			} else {
				api.start({
					from: { angle: -1 },
					to: [{ angle: 1 }, { angle: -1 }],
					loop: true,
					config: {
						duration: 5000,
						easing: easings.linear,
					},
					onChange,
				})
			}
		} else {
			api.stop()
		}
	}, [args.animate])

	return api
}
