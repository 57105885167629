/*

Tools for reading a mobile device's orientation sensor data.

*/

import { useEffect } from "react"

const ROTATION_SPEED = 0.00049 // speed constant for how fast to go from -1 to 1 given device orientation in radians

interface viewDeltaType {
	x: number
	y: number
}

// screen.orientation is only supported on safari 16.4+ so we need to fallback to the window orientation API in that case.
function getAvailableOrientationAPI() {
	if (
		window.screen &&
		window.screen.orientation &&
		typeof window.screen.orientation.type !== "undefined" &&
		typeof window.screen.orientation.angle !== "undefined"
	) {
		return "screenOrientation"
	} else if (typeof window.orientation !== "undefined") {
		return "windowOrientation"
	} else {
		return "none"
	}
}

// handles the device orientation if the device is portrait/landscape/upside down
function getOrientationState() {
	const availableAPI = getAvailableOrientationAPI()

	if (availableAPI === "screenOrientation") {
		const orientationType = window.screen.orientation.type
		const orientationAngle = window.screen.orientation.angle
		const isLandscape = orientationType.startsWith("landscape")
		const isUpsideDown = isLandscape ? orientationAngle === 270 : orientationAngle === 180

		return { isLandscape, isUpsideDown }
	} else if (availableAPI === "windowOrientation") {
		const orientationAngle = window.orientation
		const isLandscape = Math.abs(orientationAngle) === 90
		const isUpsideDown =
			orientationAngle === 180 || orientationAngle === -90 || (isLandscape && orientationAngle === -180)

		return { isLandscape, isUpsideDown }
	} else {
		return { isLandscape: false, isUpsideDown: false }
	}
}

/// Invokes the given callback with a number from -1 to 1 for the user tilting their device left and right.
export default function useGyro(onViewDelta: (viewDelta: viewDeltaType) => void, dependencies: any[] = []) {
	let deps = [onViewDelta, ...dependencies]

	useEffect(() => {
		const gyro = new Gyro(onViewDelta)
		return () => {
			gyro.shutdown()
		}
	}, deps)
}

interface DeviceRotation {
	alpha: number
	beta: number
	gamma: number
}

function clamp(val, min, max) {
	return Math.min(max, Math.max(min, val))
}

function notReady(e: DeviceMotionEventRotationRate) {
	// escape if there is no gyro data, this happens on desktop a couple times for some reason
	return !e.alpha && !e.beta && !e.gamma
}

class Gyro {
	onViewDelta: (viewDelta: viewDeltaType) => void

	rot: DeviceRotation // here we accumulate devicemotion event.rotationRate values over time
	viewDelta: number | null
	hasChange: boolean = false
	orientationAngle: number = 0

	constructor(onViewDelta: (viewDelta: viewDeltaType) => void) {
		this.onViewDelta = onViewDelta
		this.rot = { alpha: 0, beta: 0, gamma: 0 }
		this.viewDelta = 0
		this.orientationAngle = 0

		window.addEventListener("devicemotion", this.onMotion)
		window.addEventListener("deviceorientation", this.onOrientation)

		// use devicemotion instead of deviceorientation for now -- solves the
		// "gimbal lock" problem when the phone is vertical (in that position,
		// tiny changes in the phone orientation result in big changes to the
		// left-right rotation)
		// window.addEventListener("deviceorientation", this.onOrientation)

		// On some devices the deviceorientation and devicemotion events may
		// come faster than we need, which just as fast as the screen is
		// refreshing, so we sync to that with requestAnimationFrame
		requestAnimationFrame(this.onAnimationFrame)
	}

	onOrientation = (e: DeviceOrientationEvent) => {
		if (!e.alpha) return
		this.orientationAngle = e.alpha
	}

	shutdown() {
		window.removeEventListener("devicemotion", this.onMotion)
		window.removeEventListener("deviceorientation", this.onOrientation)
	}

	onAnimationFrame = () => {
		if (this.hasChange) {
			const { isLandscape, isUpsideDown } = getOrientationState()

			let xValue = isLandscape ? -this.rot.alpha : this.rot.beta
			let yValue = isLandscape ? this.rot.gamma : this.rot.alpha

			if (isUpsideDown) {
				xValue = -xValue
				yValue = -yValue
			}

			this.onViewDelta({
				x: xValue,
				y: yValue,
			})
			this.hasChange = false
		}

		requestAnimationFrame(this.onAnimationFrame)
	}

	onMotion = (e: DeviceMotionEvent) => {
		let delta = e.rotationRate
		if (delta !== null) {
			if (notReady(delta)) return
		}

		if (delta === null) return
		let { alpha, beta, gamma } = delta

		if (alpha === null || beta === null || gamma === null) return

		let val = this.rot
		val.alpha = clamp(val.alpha + -alpha * ROTATION_SPEED, -1, 1)
		val.beta = clamp(val.beta + beta * ROTATION_SPEED, -1, 1)
		val.gamma = clamp(val.gamma + gamma * ROTATION_SPEED, -1, 1)
		this.hasChange = true
	}

	/*
	onOrientation = (e: DeviceOrientationEvent) => {
		if (notReady(e)) return
		const landscape = isLandscape()
		let value = landscape ? e.beta : e.gamma
		let orthogonal = landscape ? e.gamma : e.beta

		if (Math.abs(Math.abs(orthogonal) - 90.0) < 5) {
			// A strategy to deal with gimbal lock when the phone is
			// vertical--but the transition back to absolute orientation is
			// hitchy so leaving this whole approach out for now.
			this.onViewDelta(this.viewDelta + (landscape ? this.rot.alpha : this.rot.beta))
		} else {
			this.viewDelta = value * 0.025
			this.onViewDelta(this.viewDelta)
		}
			this.hasChange = true
		}
    */
}
