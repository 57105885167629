// @ts-ignore
import fragShader from "components/embed/shaders/rgbd.frag"
// @ts-ignore
import vertShader from "components/embed/shaders/rgbd.vert"

import { Hologram } from "@prisma/client"
import { useTexture } from "@react-three/drei"
import { invalidate, ThreeEvent, useFrame } from "@react-three/fiber"
import { useEffect, useMemo, useRef, useState, useDeferredValue } from "react"
import * as THREE from "three"
import { Mesh, Vector2 } from "three"
import CustomShaderMaterial from "three-custom-shader-material"
import { RendererProps } from "../HologramEmbed"
import DisplacedGeometry from "./DisplacedGeometry"
import useAnimatedValue from "hooks/useAnimatedValue"
import { isDesktop } from "react-device-detect"
import { HOLOGRAM_DEFAULT_ASPECT_RATIO, HOLOGRAM_DEFAULT_RGBD_ZOOM } from "@/prisma/models"

interface RGBDShaderHologram
	extends Pick<Hologram, "aspectRatio" | "rgbdDepthiness" | "rgbdZoom" | "rgbdFocus"> {}
export interface RGBDShaderArgs {
	overrideAspect: number
	diffuse: string
	depth: string
	dimensions: { x: number; y: number }
	blurredImage: string
	backgroundBlur: string
	depthiness: number
	stretch: number
	focus: number
	BGscale: number
	highRange: number
	hologram: RGBDShaderHologram
	props: RendererProps
	hideCursor?: boolean
}

export function RGBDShader({
	diffuse,
	depth,
	dimensions,
	blurredImage,
	backgroundBlur,
	depthiness,
	BGscale,
	stretch,
	focus,
	hologram,
	props,
	hideCursor = false,
}: RGBDShaderArgs) {
	// controls for background blur
	const [isFocusVisualizerVisible, setIsFocusVisualizerVisible] = useState<number>(0)
	const animatedFocusVisualizer = useAnimatedValue(isFocusVisualizerVisible)
	const [interactionTimeout, setInteractionTimeout] = useState<NodeJS.Timeout | null>(null)
	const deferredDepthiness = useDeferredValue(depthiness)
	const meshCursorRef = useRef<THREE.Mesh | null>(null)
	const depthCanvas = useRef<HTMLCanvasElement | null>(null)
	const depthImageRef = useRef<HTMLImageElement | null>(null)
	const setHologramFocus = props.setFocus
	const { isEditMode } = props
	const cursorPosition = useRef([0, 0])
	const clickToFocusValue = useRef(0)

	const initialFocus = useRef(focus)
	const initialDepthiness = useRef(depthiness)

	if (!blurredImage) {
		console.error("RGBDShader: blurred image is not defined")
	}

	// This reference will give us direct access to the mesh
	const mesh = useRef<Mesh | null>(null)
	const u_diffuse = useTexture(diffuse)
	u_diffuse.encoding = THREE.sRGBEncoding
	u_diffuse.format = THREE.RGBAFormat
	u_diffuse.anisotropy = 0
	u_diffuse.minFilter = THREE.NearestFilter
	u_diffuse.magFilter = THREE.NearestFilter
	const u_depth = useTexture(depth)
	u_depth.anisotropy = 0
	u_depth.minFilter = THREE.NearestFilter
	u_depth.magFilter = THREE.NearestFilter
	const blurredDiffuseTex = useTexture(blurredImage ?? "")
	const blurredBackgroundTex = useTexture(backgroundBlur ?? "")
	blurredDiffuseTex.anisotropy = 0
	blurredDiffuseTex.encoding = THREE.sRGBEncoding
	blurredDiffuseTex.minFilter = THREE.NearestFilter
	blurredDiffuseTex.magFilter = THREE.NearestFilter

	const uniforms = useMemo(
		() => ({
			u_resolution: { value: new Vector2(dimensions.x ?? 1, dimensions.y ?? 1) },
			u_threshold: { value: new Vector2(0.1, 0.1) },
			u_pixelRatio: { value: 1 },
			color: { value: u_diffuse },
			tex: { value: u_depth },
			u_time: { value: 0 },
			blurRadius: { value: 8 },
			u_backgroundScale: { value: 1 },
			u_highRange: { value: stretch },
			u_depthiness: { value: depthiness },
			maxDistance: { value: 1 },
			u_colorMode: { value: 0 },
			u_stretchy: { value: 0.003 },
			u_focus: { value: focus },
			u_tolerance: { value: 0.1 },
			u_blurEnabled: { value: 0.0 },
			u_isFocusVisible: { value: isFocusVisualizerVisible },
			u_blurredTexture: { value: blurredDiffuseTex },
		}),
		[],
	)

	useFrame((state) => {
		if (mesh !== null && mesh.current !== null && mesh.current.material !== null) {
			let material = mesh.current.material as THREE.ShaderMaterial
			material.uniforms.u_depthiness.value = depthiness
			material.uniforms.u_backgroundScale.value = BGscale
			material.uniforms.u_highRange.value = stretch
			material.uniforms.u_colorMode.value = 0
			material.uniforms.u_focus.value = initialFocus.current
			material.uniforms.u_isFocusVisible.value = animatedFocusVisualizer.get()
		}
	})

	const [isPointerDown, setIsPointerDown] = useState(false)

	useEffect(() => {
		if (isPointerDown) {
			// Skip the useEffect logic if the pointer down is active
			return
		}

		// Check if the values have changed from their initial states
		if (focus !== initialFocus.current || depthiness !== initialDepthiness.current) {
			setIsFocusVisualizerVisible(1)
			invalidate()

			if (interactionTimeout) {
				clearTimeout(interactionTimeout)
				setInteractionTimeout(null)
				invalidate()
			}

			const timeoutId = setTimeout(() => {
				setIsFocusVisualizerVisible(0)
				invalidate()
			}, 1000) as unknown as NodeJS.Timeout

			setInteractionTimeout(timeoutId)
		}

		initialFocus.current = focus / depthiness
		initialDepthiness.current = depthiness
	}, [depthiness, focus])

	// this use effect is solely responsible for setting up the canvas initially
	// sometimes it doesn't generate when the hologram loads
	// to compensate we use isPointerDown in the dependency array
	useEffect(() => {
		if (!isEditMode) return
		if (!isPointerDown) return

		if (depthCanvas.current) {
			return
		}

		if (depthImageRef.current === null) {
			const depthImage = new Image()
			depthImage.crossOrigin = "anonymous"
			depthImage.src = depth
			depthImageRef.current = depthImage
		}

		const image = depthImageRef.current

		const canvas = document.createElement("canvas")
		canvas.id = "DEPTH_CANVAS"

		depthCanvas.current = canvas

		const ctx = canvas.getContext("2d")
		canvas.height = image.height
		canvas.width = image.width

		image.onload = async () => {
			ctx?.drawImage(image, 0, 0, image.width, image.height)
		}
	}, [focus, isPointerDown])

	hologram.aspectRatio = hologram.aspectRatio ?? HOLOGRAM_DEFAULT_ASPECT_RATIO
	hologram.rgbdZoom = hologram.rgbdZoom ?? HOLOGRAM_DEFAULT_RGBD_ZOOM

	const handlePointerDown = async (e: ThreeEvent<PointerEvent>) => {
		cursorPosition.current = [e.clientX, e.clientY]
		setIsPointerDown(true)
		if (!isEditMode) return
		if (!setHologramFocus) return
		if (!mesh.current) return
		if (!depthCanvas.current) return

		const uv = e.uv
		if (!uv) return
		const ctx = depthCanvas.current.getContext("2d", { willReadFrequently: true })
		// Get the image data at coordinates (x, y)
		// Transform UV coordinates to pixel coordinates
		const x = Math.floor(uv.x * depthCanvas.current.width)
		const y = Math.floor((1 - uv.y) * depthCanvas.current.height)

		const imageData = ctx?.getImageData(x, y, 1, 1).data
		// Extract the RGBA values
		//@ts-ignore
		const [r, g, b, a] = imageData

		const value = r
		const convertedValue = convertRange(value)

		const invertedValue = convertedValue * -1
		initialFocus.current = invertedValue

		clickToFocusValue.current = invertedValue * depthiness

		// clear existing timeouts
		if (interactionTimeout) {
			clearTimeout(interactionTimeout)
			invalidate()
		}

		// start a new timeout
		// this will cause the focus visualizer to disappear after we've stoped editing settings
		const timeoutId = setTimeout(() => {
			setIsFocusVisualizerVisible(0)
			setIsPointerDown(false) // Reset the flag after timeout
			invalidate()
		}, 600) as unknown as NodeJS.Timeout

		setInteractionTimeout(timeoutId)
	}

	const handlePointerUp = async (e: ThreeEvent<PointerEvent>) => {
		if (!setHologramFocus) return
		if (!isEditMode) return
		// first, get the x, y location
		const pointerUpAt = [e.clientX, e.clientY]

		// then compare against the initial pointerdown coordinates
		const deltaX = Math.abs(pointerUpAt[0] - cursorPosition.current[0])
		const deltaY = Math.abs(pointerUpAt[1] - cursorPosition.current[1])

		// if delta is too great, we're doing a click and drag
		if (deltaX > 0.1 || deltaY > 0.1) return

		setHologramFocus(clickToFocusValue.current)
		setIsPointerDown(true) // Set the flag to indicate pointer down
		setIsFocusVisualizerVisible(1)
		// request a new frame from r3f
		invalidate()

		// clear existing timeouts
		if (interactionTimeout) {
			clearTimeout(interactionTimeout)
			invalidate()
		}

		// start a new timeout
		// this will cause the focus visualizer to disappear after we've stoped editing settings
		const timeoutId = setTimeout(() => {
			setIsFocusVisualizerVisible(0)
			setIsPointerDown(false) // Reset the flag after timeout
			invalidate()
		}, 600) as unknown as NodeJS.Timeout

		setInteractionTimeout(timeoutId)
	}

	function convertRange(value) {
		// Ensure the value is within the 0-255 range and is an integer
		value = Math.floor(value)
		if (value < 0 || value > 255) {
			throw new RangeError("Value must be between 0 and 255")
		}

		// Convert the value from the range 0-255 to -1 to 1
		return value / 127.5 - 1
	}

	function onMouseMove(e: ThreeEvent<PointerEvent>) {
		if (!isEditMode) return
		if (!meshCursorRef.current) return
		const { x, y, z } = e.point

		meshCursorRef.current?.position.set(x, y, z + 0.2)
	}

	return (
		<>
			<group position={[0, 0, 0]}>
				{/* actual displaced mesh */}
				<DisplacedGeometry
					colorUrl={diffuse}
					depthUrl={depth}
					aspect={hologram?.aspectRatio ?? 1}
					depthiness={deferredDepthiness}
					onPointerDown={handlePointerDown}
					onPointerUp={handlePointerUp}
					onPointerMove={(e) => onMouseMove(e)}
					meshRef={mesh}>
					<CustomShaderMaterial
						fragmentShader={fragShader}
						baseMaterial={THREE.MeshBasicMaterial}
						vertexShader={vertShader}
						uniforms={uniforms}
						wireframe={false}
						toneMapped={false}
						transparent={true}
					/>
				</DisplacedGeometry>
				{/* far background mesh */}
				<mesh visible={true} position={[0, 0, -1.0]}>
					<planeGeometry args={[hologram.aspectRatio * 7, 7, 1, 1]} />
					<meshBasicMaterial map={blurredBackgroundTex} />
				</mesh>
			</group>
			{/* only render the cursor if we're on the edit page in desktop mode */}
			{isEditMode && !hideCursor && isDesktop && (
				<mesh ref={meshCursorRef} scale={[0.3, 0.3, 0.3]} renderOrder={9999}>
					<sphereGeometry args={[0.1, 16, 16]} />
					<meshBasicMaterial color="white" opacity={0.9} transparent />
				</mesh>
			)}
		</>
	)
}
