import { trpc } from "lib/trpc/trpc"
import { useState, useEffect } from "react"
import moment from "moment-timezone"

export default function useTrackLoadTime(loaded: boolean, quiltSize: number) {
	const [time, setTime] = useState(new Date().getTime())
	const [elapsedTime, setElapsedTime] = useState(0)
	const [called, setCalled] = useState(false)

	const { mutate } = trpc.stats.hologramLoad.useMutation()

	useEffect(() => {
		if (loaded && !called) {
			const et = new Date().getTime() - time
			setElapsedTime(et)

			const zone = moment.tz.guess()
			mutate({ duration: et, timezone: zone, quiltSize })
			setCalled(true)
		}
	}, [loaded])

	return {
		elapsedTime,
	}
}
