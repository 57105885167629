import { useSpring } from "@react-spring/web"
import { useEffect } from "react"

export default function useAnimatedValue(targetValue: number, config = { tension: 120, friction: 14 }) {
	const [{ value }, set] = useSpring(() => ({ value: targetValue }))

	useEffect(() => {
		set({ value: targetValue })
	}, [targetValue, set])

	return value
}
