import { Canvas, invalidate, RootState } from "@react-three/fiber"
import { classNames } from "lib/classNames"
import useHologramControls from "hooks/useHologramControls"
import { useControls } from "leva"
import { MathUtils, Vector3 } from "three"
import { MutableRefObject, useCallback, useEffect, useState, useRef } from "react"
import Dropshadow from "../Dropshadow"
import { RendererProps } from "../HologramEmbed"
import Quilt from "./Quilt"

// @refresh reset

// Uncomment WebGL1Renderer import above and FORCE_WEBGL1 usage below to force
// react-three-fiber to use a webgl1 context, instead of the default, which on
// most browsers these days is going to be WebGL2.
export const FORCE_WEBGL1 = false
export const FOV = 22

/**
 * @deprecated
 */
export default function THREEHologramRenderer(props: RendererProps) {
	const { width, height, containerRef: elementRef } = props
	const threeObjectsGroup = useRef<THREE.Group>(null)
	const [rootState, setRootState] = useState<RootState>()
	const [isCanvasLoaded, setCanvasLoaded] = useState<boolean>(false)
	const [isQuiltLoaded, setQuiltLoaded] = useState<boolean>(false)
	const viewconeRad = props.viewcone * MathUtils.DEG2RAD
	const { radius } = useControls({ radius: 0.03 })
	const isReady = width > 0 && height > 0 && isCanvasLoaded

	// profiling loading
	const [startingTime, setStartingTime] = useState(performance.now())
	useEffect(() => {
		if (process.env.NODE_ENV === "development") console.log(`starting time: ${startingTime}`)
	}, [startingTime])

	function onCanvasCreated(state: RootState) {
		setCanvasLoaded(true)
		setRootState(state)
	}

	function onQuiltLoadComplete() {
		setQuiltLoaded(true)
		props.onLoad?.()
		if (process.env.NODE_ENV === "development")
			console.log(`quilt loaded: ${performance.now() - startingTime}\ntime: ${performance.now()}`)
	}

	const calculateCameraDist = useCallback(
		(width: number, height: number, contentAspect: number, FOV, viewconeRad) => {
			const canvasAspect = width / height
			const contentWidth = contentAspect > 1 ? 1 : contentAspect
			const contentHeight = contentAspect > 1 ? 1 / contentAspect : 1

			// distance for vertical framing
			let vertDist = (0.5 * contentHeight) / Math.tan(MathUtils.degToRad(0.5 * FOV))
			// also need to account for how far forward it will be pushed when turned all the way
			vertDist += 0.5 * contentWidth * Math.sin(0.5 * viewconeRad)

			// distance for horizontal framing
			const hfovRad = 2 * Math.atan(Math.tan(MathUtils.degToRad(0.5 * FOV)) * canvasAspect)
			const minAngleRad = Math.min(0.5 * hfovRad, 0.5 * viewconeRad)
			const nx = 0.5 * contentWidth * Math.cos(minAngleRad)
			const nz = 0.5 * contentWidth * Math.sin(minAngleRad)
			let horizDist = nz + nx / Math.tan(0.5 * hfovRad)

			// take the bigger of the two and push back a bit further to give room for
			// a drop shadow
			return Math.max(vertDist, horizDist) * (props.dropshadow ? 1.1 : 1)
		},
		[props.dropshadow],
	)

	// this sets up the camera at the proper distance when ready
	useEffect(() => {
		if (rootState && props.width > 0 && props.height > 0) {
			const dist = calculateCameraDist(
				props.width,
				props.height,
				props.hologram?.aspectRatio ? props.hologram?.aspectRatio : 1,
				FOV,
				viewconeRad,
			)
			rootState.camera.position.set(0, 0, dist)

			// Reset the camera's rotation, since a VR helmet may have pointed it anywhere.
			rootState.camera.setRotationFromAxisAngle(new Vector3(0, 0, 1), 0)
		}
	}, [props, rootState])

	// Unload the canvas
	useEffect(() => {
		const el = elementRef?.current
		return () => {
			if (el) {
				const canvasEl = el as HTMLCanvasElement
				canvasEl.width = 1
				canvasEl.height = 1
			}
			setCanvasLoaded(false)
		}
	}, [elementRef])

	// Setup GL context
	// const gl = useCallback((canvas: HTMLCanvasElement) => {
	// 	if (FORCE_WEBGL1) {
	// 		return new WebGL1Renderer({ canvas, antialias: true, alpha: true })
	// 	}
	// 	return undefined
	// }, [])

	// add the THREE functionality to the onViewAngleChange callback passed to controls here
	const onViewAngleChange = useCallback(
		(normalizedRotY) => {
			props.onViewAngleChange?.(normalizedRotY, 0)
			threeObjectsGroup.current?.rotation.set(0, MathUtils.degToRad(normalizedRotY * props.viewcone * 0.5), 0)
			invalidate()
		},
		[threeObjectsGroup],
	)

	// controls
	useHologramControls({
		onViewAngleChange: onViewAngleChange,
		isQuiltLoaded: isQuiltLoaded,
		rendererProps: props,
	})

	if (!props.hologram) {
		console.error("No hologram provided to THREEHologramRenderer")
		return <></>
	}

	return (
		<Canvas
			style={{ position: "inherit" }}
			id="hologram-element"
			className={classNames(
				isCanvasLoaded && isReady ? "opacity-100" : "opacity-0",
				`transition-opacity duration-200`,
			)}
			frameloop="demand"
			// gl={gl}
			ref={elementRef as MutableRefObject<HTMLCanvasElement>}
			onCreated={onCanvasCreated}
			camera={{ fov: FOV }}>
			{isReady && props.hologram.type == "QUILT" && (
				<group ref={threeObjectsGroup}>
					{props.dropshadow && (
						<Dropshadow aspectRatio={props.hologram?.aspectRatio ? props.hologram?.aspectRatio : 1} />
					)}
					<Quilt
						hologram={props.hologram}
						resolution={width}
						radius={radius}
						onLoadComplete={onQuiltLoadComplete}
						viewcone={props.viewcone}
					/>
				</group>
			)}
		</Canvas>
	)
}
