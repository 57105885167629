// For trpc code only: This is temporary until the app is fully strictNullCheck=true

import { Hologram } from "graphql/client/gql/types"
import { trpc } from "lib/trpc/trpc"
import { useEffect, useState } from "react"

interface PageViewProps {
	hologram?: Pick<Hologram, "id" | "uuid" | "privacy"> | null
	skip?: boolean
}

/**
 * Use this hook to track page views for a hologram
 * @param hologramId
 * @param skip If true, dont send the page view. Default `false`
 */
export default function useHologramPageView({ hologram, skip = false }: PageViewProps) {
	const [viewed, setViewed] = useState<boolean>(false)
	const stats = trpc.stats.hologramPageView.useMutation()

	const lookup = hologram?.privacy == "UNLISTED" ? hologram?.uuid : hologram?.id?.toString()

	useEffect(() => {
		if (!viewed && !skip && lookup) {
			setViewed(true)
			stats.mutate({
				lookup,
			})
		}
	}, [hologram?.id, skip])
}
