import { Canvas, invalidate } from "@react-three/fiber"
import useHologramControls from "hooks/useHologramControls"
import useImagePreloader from "hooks/useImagePreloader"
import useTrackLoadTime from "hooks/useTrackLoadTime"
import { getCDNUrl } from "lib/cdn"
import { classNames } from "lib/classNames"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Group } from "three"
import { RendererProps } from "../HologramEmbed"
import { BlurredPreviewImage, BorderFrameAnimation } from "./HTMLHologramRenderer"
import { RGBDShader } from "./RGBDShader"
import {
	findSourceImage,
	HOLOGRAM_DEFAULT_RGBD_DEPTHINESS,
	HOLOGRAM_DEFAULT_RGBD_FOCUS,
} from "@/prisma/models"

const FOV = 22
const wiggleAmount = 20

function normalizeRange(value, inMin, inMax, outMin, outMax) {
	return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

export default function RGBDHologramRenderer(props: RendererProps) {
	const block = useRef<Group>(null)
	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const {
		hologram,
		depthOverrideUrl,
		frameTilt,
		height,
		width,
		onLoad,
		onViewAngleChange,
		dropshadow,
		animate,
		overrideAspect: overrideAspectRatio,
		isEditMode,
		viewcone,
	} = props
	const aspect = hologram?.aspectRatio ?? 1
	const depth = hologram?.rgbdDepthiness ?? HOLOGRAM_DEFAULT_RGBD_DEPTHINESS
	const focus = hologram?.rgbdFocus ?? HOLOGRAM_DEFAULT_RGBD_FOCUS
	const overrideAspect = isEditMode ? (overrideAspectRatio ?? 1) : aspect

	const [showThumbnail, setShowThumbnail] = useState(false)
	const [hideCursor, setHideCursor] = useState(false)

	const depthAsset = hologram?.imageAssets?.find((asset) => asset.kind === "DEPTH")
	const colorAsset = hologram && findSourceImage(hologram)
	const midBlur =
		hologram?.imageAssets?.[0] ?? hologram?.imageAssets?.find((asset) => asset.kind === "SOURCE")
	const backgroundBlur =
		hologram?.imageAssets?.[0] ?? hologram?.imageAssets?.find((asset) => asset.kind === "SOURCE")
	const [assetsLoaded, setAssetsLoaded] = useState(!!depthAsset)

	const hologramRef = useRef<HTMLDivElement>(null)
	const perspectiveContainerRef = useRef<HTMLDivElement>(null)
	const quiltImageWidth = useRef<number>(0)

	const resizedDiffuse = getCDNUrl(colorAsset!?.url, { width: 1200 * aspect, format: "jpeg", height: 1200 })
	const resizedDepth = depthAsset
		? getCDNUrl(depthAsset.url, { width: 1200 * aspect, format: "jpeg", height: 1200 })
		: undefined
	const resizedMidBlurred = getCDNUrl(midBlur!.url, { width: 1200 * aspect, format: "jpeg", height: 1200 })
	const resizedBackgroundBlur = getCDNUrl(backgroundBlur!.url, {
		blur: 40,
		width: 1200 * aspect,
		format: "jpeg",
		height: 1200,
	})

	//it's possible our hologram may not be loaded
	useEffect(() => {
		const loaded = !!resizedDepth
		setAssetsLoaded(loaded)
	}, [depthAsset])

	// this sets the perspective value of the css transform
	// makes sure the perspective is consistent across varying div sizes
	const perspectiveSize = useMemo(() => {
		if (height) {
			return (height * 2).toFixed(0)
		}
		return "2400"
	}, [height])

	function setContainerView(rotY: number, rotX: number) {
		if (!frameTilt) return

		const rotateY = ((rotY - 0.5) * viewcone).toFixed(2)
		const rotateX = ((rotX - 0.5) * viewcone).toFixed(2)
		perspectiveContainerRef.current?.setAttribute(
			"style",

			`transform: perspective(${perspectiveSize}px) rotateY(${rotateY}deg) rotateX(${-rotateX}deg);` +
				`transform-style: preserve-3d;`,
		)
	}

	const sourceImageurl = hologram && findSourceImage(hologram)?.url
	const blurredPreviewUrl =
		sourceImageurl &&
		getCDNUrl(sourceImageurl, { width: 1200 * aspect, format: "jpeg", height: 1200, blur: 20 })

	// rgbd preloading
	// use the image preloader hook to detect when the rgbd is finished loading
	const { imagesPreloaded: thumbnailPreloaded } = useImagePreloader([blurredPreviewUrl])
	const { imagesPreloaded: sourceImagePreloaded, totalCachedImages } = useImagePreloader([sourceImageurl])

	// Track when quilt finishes loading (only if it's not cached)
	useTrackLoadTime(sourceImagePreloaded && totalCachedImages == 0, quiltImageWidth.current)

	/**
	 * Manually trigger the display of the thumbnail & quilt
	 * so that we have more control over the animation
	 */
	useEffect(() => {
		if (sourceImagePreloaded && width > 0) {
			onLoad?.()
			setShowThumbnail(true)
		}
		if (thumbnailPreloaded) {
			setShowThumbnail(true)
		}

		if (!thumbnailPreloaded) {
			setShowThumbnail(false)
		}
	}, [thumbnailPreloaded, sourceImagePreloaded, width])

	// wiggle controls
	const wiggle = useCallback(
		(normalizedRotX, containerX, normalizedRotY, containerY) => {
			props?.onViewAngleChange?.(normalizedRotX, containerX, normalizedRotY, containerY)
			if (frameTilt) {
				setContainerView(containerX * 0.25 + 0.5, containerY * 0.25 + 0.5)
			}
			// set the rotation of the RGBD mesh
			block.current?.rotation?.set(
				(normalizedRotY * Math.PI) / (wiggleAmount / 1),
				(normalizedRotX * Math.PI) / (wiggleAmount / 1),
				0,
			)

			invalidate()
		},
		[onViewAngleChange, animate],
	)

	// controls
	useHologramControls({
		onViewAngleChange: (xRot, xContainer, yRot, yContainer) => wiggle(xRot, xContainer, yRot, yContainer),
		isQuiltLoaded: true,
		rendererProps: props,
		circularRotation: true,
	})

	useEffect(() => {
		if (!hologramRef.current) return
		if (!canvasRef.current) return

		// transform controls
		const hologramZoom = hologram?.rgbdZoom ?? 1
		const zoom = normalizeRange(hologramZoom, 0.5, 2, 1, 4)
		// Calculate transform values based on crop position
		const cropPosX = (hologram?.crop_pos_x ?? 0) * -100 * zoom
		const cropPosY = (hologram?.crop_pos_y ?? 0) * 100 * zoom

		// Update hologram's transform style with translate3d for 3D support
		hologramRef.current.style.transform = `translate3d(${cropPosX}%, ${cropPosY}%, 0) scale(${zoom})`

		hologramRef.current.style.transformStyle = "preserve-3d"

		canvasRef.current.style.width = "100%"
		canvasRef.current.style.height = "100%"
	}, [hologram?.crop_pos_x, hologram?.crop_pos_y, hologram?.rgbdZoom, overrideAspect, isEditMode])

	if (!hologram) return <></>

	return (
		<div
			id="hologram-element"
			className={classNames("flex h-full w-full justify-center", `${isEditMode ? "cursor-none" : ""}`)}
			ref={perspectiveContainerRef}
			onMouseLeave={() => setHideCursor(true)}
			onMouseEnter={() => setHideCursor(false)}>
			<div
				style={{
					aspectRatio: overrideAspect,
					maxHeight: "100%",
					width: "100%",
					maxWidth: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					overflow: "hidden",
				}}
				className={`self-center rounded-xl ${isEditMode ? "border-2 border-solid border-white/40 bg-black/50 backdrop-blur-3xl" : ""}`}>
				<div
					ref={hologramRef}
					style={{
						aspectRatio: aspect,
						width: aspect >= (overrideAspect ?? 1) ? "100%" : "auto",
						height: aspect >= (overrideAspect ?? 1) ? "auto" : "100%",
						maxWidth: "100%",
						maxHeight: "100%",
					}}>
					<div
						className={classNames(
							dropshadow ? "shadow-xl" : "",
							"pointer-events-none relative h-full w-full overflow-hidden",
						)}>
						{/* RGBD Container */}
						<div
							className={classNames(
								"absolute z-20 h-full w-full transition-opacity duration-[700ms] ease-in-out",
								"overflow-visible",
							)}>
							<Canvas
								key={overrideAspect}
								ref={canvasRef}
								camera={{ fov: FOV }}
								dpr={[1, 3]}
								frameloop="always"
								onCreated={() => {
									if (!canvasRef.current) return
									;(canvasRef.current.style.width = "100%"), (canvasRef.current.style.height = "100%")
								}}>
								{/* for debugging */}
								{/* <color attach="background" args={[1, 1, 0]} /> */}
								<group>
									{hologram && resizedDepth && (
										<group ref={block}>
											{/* extrusion shader */}
											<RGBDShader
												overrideAspect={hologram.aspectRatio ?? 1}
												diffuse={resizedDiffuse}
												depth={depthOverrideUrl ?? resizedDepth}
												dimensions={{
													x: colorAsset?.width ?? 1,
													y: colorAsset?.height ?? aspect,
												}}
												focus={focus}
												blurredImage={resizedMidBlurred}
												backgroundBlur={resizedBackgroundBlur}
												stretch={1}
												depthiness={depth}
												BGscale={1.0}
												highRange={0.0}
												hologram={hologram}
												props={props}
												hideCursor={hideCursor}
											/>
										</group>
									)}
								</group>
							</Canvas>
						</div>
						{resizedDiffuse && (
							<BlurredPreviewImage
								visible={showThumbnail}
								quiltLoaded={assetsLoaded}
								className={"z-[28] blur-xl duration-500"}
								src={resizedDiffuse}
							/>
						)}

						<BorderFrameAnimation
							visible={!assetsLoaded}
							quiltLoaded={assetsLoaded}
							className={"z-[25] duration-500"}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
