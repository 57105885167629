import { useEffect, useState } from "react"

interface ImagePreloader {
	src: string
	cached?: boolean
}

function preloadImage(src: string) {
	return new Promise<ImagePreloader>((resolve, reject) => {
		let img = new Image()
		img.decoding = "async"

		img.onload = async function () {
			resolve({ src })
		}
		img.onerror = img.onabort = function () {
			reject({ src })
		}
		img.src = src

		// image is cached
		if (img.complete) {
			resolve({ src, cached: true })
			return
		}
	})
}

export default function useImagePreloader(imageList: (string | undefined | null)[]) {
	const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)
	const [totalCachedImages, setTotalCachedImges] = useState<number>(0)

	useEffect(() => {
		const images = imageList.filter((image) => typeof image === "string") as string[]

		let isCancelled = false

		async function effect() {
			// console.log("PRELOAD CHECK", imageList)
			if (isCancelled) {
				return
			}

			try {
				const resp = await Promise.all(images.map((image) => preloadImage(image)))
				setTotalCachedImges(resp.filter((r) => r.cached === true).length)

				if (isCancelled) {
					return
				}

				setImagesPreloaded(true)
			} catch (e) {
				setImagesPreloaded(false)
			}
		}

		effect()

		return () => {
			isCancelled = true
		}
	}, [imageList.join(",")])

	useEffect(() => {
		setImagesPreloaded(false)
	}, [imageList])

	return { imagesPreloaded, totalCachedImages }
}
