import { isMobileSafari } from "react-device-detect"
import {
	CanvasTexture,
	ImageBitmapLoader,
	ImageLoader,
	LinearFilter,
	Loader,
	LoadingManager,
	Texture,
} from "three"

// controls whether to use async texture loading via ImageBitmap
const USE_IMAGE_BITMAP_WHEN_POSSIBLE = true

// Texture options in common for quilt textures.
const setQuiltTextureOptions = (texture: Texture) => {
	texture.minFilter = LinearFilter // we want a linear minification filter
	texture.generateMipmaps = false // we don't need mipmaps
}

class QuiltImageBitmapLoader extends Loader {
	load(
		url: string,
		onLoad?: (texture: Texture) => void,
		onProgress?: (event: ProgressEvent) => void,
		onError?: (event: ErrorEvent) => void
	) {
		const loader = new ImageBitmapLoader(this.manager)
		loader.setOptions({ imageOrientation: "flipY", premultiplyAlpha: "none" })
		loader.setCrossOrigin(this.crossOrigin)
		loader.setPath(this.path)

		loader.load(
			url,
			function (imageBitmap: ImageBitmap) {
				const texture = new CanvasTexture(imageBitmap)
				setQuiltTextureOptions(texture)
				onLoad?.(texture)
			},
			onProgress,
			onError
		)
	}
}

class QuiltTextureLoader extends Loader {
	load(
		url: string,
		onLoad?: (texture: Texture) => void,
		onProgress?: (event: ProgressEvent) => void,
		onError?: (event: ErrorEvent) => void
	) {
		const loader = new ImageLoader(this.manager)
		loader.setCrossOrigin(this.crossOrigin)
		loader.setPath(this.path)

		loader.load(
			url,
			function (image) {
				const texture = new Texture()
				setQuiltTextureOptions(texture)
				texture.image = image
				texture.needsUpdate = true
				onLoad?.(texture)
			},
			onProgress,
			onError
		)

		return null
	}
}

let QuiltLoader: new (manager: LoadingManager) => Loader

// Use ImageBitmap if the browser supports it.
if (USE_IMAGE_BITMAP_WHEN_POSSIBLE && !isMobileSafari && typeof createImageBitmap !== "undefined") {
	QuiltLoader = QuiltImageBitmapLoader
} else {
	QuiltLoader = QuiltTextureLoader
}
export default QuiltLoader
