import { Shadow } from "@react-three/drei"
import { folder, useControls } from "leva"

export default function Dropshadow({ aspectRatio }) {
	const {
		baseScale: shadowBaseScale,
		yOffset: shadowYOffset,
		rotation: shadowRotation,
		opacity: shadowOpacity,
	} = useControls({
		shadow: folder({
			opacity: 0.21,
			baseScale: [1.0, 0.23, 1.0],
			yOffset: -0.02,
			rotation: [-Math.PI / 2.5, 0, 0],
		}),
	})

	const aspectScale: [number, number, number] =
		aspectRatio > 1 ? [1, 1 / aspectRatio, 1] : [aspectRatio, 1, 1]

	const shadowScale: [number, number, number] = [
		aspectScale[0] * shadowBaseScale[0],
		aspectScale[0] * shadowBaseScale[1],
		shadowBaseScale[2],
	]
	const shadowPositionY = (-0.5 + shadowYOffset) * aspectScale[1]

	return (
		<Shadow
			opacity={shadowOpacity}
			scale={shadowScale}
			position-y={shadowPositionY}
			rotation={shadowRotation}
		/>
	)
}
